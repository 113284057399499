import React from 'react'
import Link from 'gatsby-link'
import Layout from "../layouts/de"

const IndexPage = (props) => (
  <Layout location={props.location}>
    <div>
      <h1>Hallo zusammen</h1>
      <p>Willkommen auf der Baustelle</p>
      <p>Hier wird gerade gearbeitet ...</p>
      <Link to="/page-2/">Zur Seite2</Link>
    </div>
  </Layout>
)

export default IndexPage
